import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DbOrder } from "../../config/firebase";

export type CurrentOrder = DbOrder;

interface IOrderState {
  items: Map<string, DbOrder>;
  currentOrder?: CurrentOrder;
}

const initialState: IOrderState = {
  items: new Map<string, DbOrder>(),
  currentOrder: undefined,
};

export const orderSlice = createSlice({
  initialState,
  name: "orderSlice",
  reducers: {
    addOrUpdateOrder: (state, action: PayloadAction<DbOrder>) => {
      const order = action.payload;
      state.items.set(order.id, order);
    },
    replaceAllOrders: (state, action: PayloadAction<Array<DbOrder>>) => {
      const orders = new Map<string, DbOrder>();

      action.payload.forEach((p) => {
        orders.set(p.id, p);
      });

      state.items = orders;
    },
    updateCurrentOrder: (
      state,
      action: PayloadAction<CurrentOrder | undefined>
    ) => {
      const order = action.payload;
      state.currentOrder = order;
    },
  },
});

export const { addOrUpdateOrder, replaceAllOrders, updateCurrentOrder } =
  orderSlice.actions;
export default orderSlice.reducer;

export const selectOrderById = (state: { order: IOrderState }, id: string) => {
  const items = state.order.items;

  if (items && items.size > 0) {
    return items.get(id);
  } else {
    return undefined;
  }
};

export const selectAllOrders = (state: { order: IOrderState }) => {
  if (state.order.items.size > 0) {
    return Array.from(state.order.items.values());
  } else {
    return [];
  }
};

export const selectOrdersByUserId = (
  state: { order: IOrderState },
  userId: string
): Array<DbOrder> => {
  return selectAllOrders(state).filter((o) => o.id === userId);
};

export const selectCurrentOrder = (state: {
  order: IOrderState;
}): CurrentOrder | undefined => {
  return state.order.currentOrder;
};
