import { useEffect, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { CollectionName, db } from "../config/firebase";

export type FilterData = {
  field: string;
  value: string;
};

export const useGetData = <T,>(
  collectionName: CollectionName,
  filter?: FilterData,
  isAuthorized?: boolean
) => {
  const [data, setData] = useState<Array<T>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthorized === undefined || isAuthorized) {
      const getData = async () => {
        const q = filter
          ? query(
              collection(db, collectionName),
              where(filter.field, "==", filter.value)
            )
          : collection(db, collectionName);

        onSnapshot(q, (snapshot) => {
          setData(
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as T))
          );
          setLoading(false);
        });
      };

      getData();
    }
  }, [collectionName, filter, isAuthorized]);

  return { data, loading };
};
