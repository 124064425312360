import { useState } from "react";
import { Col, Row } from "reactstrap";
import { useAppSelector } from "../../../redux/store";
import {
  selectTotalCartQuantity,
  selectTotalCartAmount,
} from "../../../redux/slices/cartSlice";
import "./checkoutNew.scss";
import { selectUser } from "../../../redux/slices/userSlice";
import PickUpWayHeader from "./pick-up-way/PickUpWayHeader";
import PickUpTime from "./pick-up-time/PickUpTime";
import YourDataHeader from "./your-data/YourDataHeader";
import Payment from "./payment/Payment";

const CheckoutNew = () => {
  const currentUser = useAppSelector(selectUser);
  const totalQty = useAppSelector(selectTotalCartQuantity);
  const totalAmount = useAppSelector(selectTotalCartAmount);

  const [selectedStep, selectStep] = useState(1);

  return (
    <Row>
      <Col md={4} className="order-md-2 fixed-column pt-3">
        <div className="checkout__cart">
          <h6>
            Total Qty: <span>{totalQty} items</span>
          </h6>
          <h6>
            Subtotal: <span>${totalAmount}</span>
          </h6>
          <h6>
            <span>
              Shipping: <br />
            </span>
            <span>$0</span>
          </h6>

          <h4>
            Total Cost: <span>${totalAmount}</span>
          </h4>
        </div>
      </Col>
      <Col md={8} className="scrollable-column pt-3">
        <PickUpWayHeader selectedStep={selectedStep} selectStep={selectStep} />
        <PickUpTime selectedStep={selectedStep} selectStep={selectStep} />
        <YourDataHeader
          selectedStep={selectedStep}
          selectStep={selectStep}
          currentUser={currentUser}
        />
        <Payment
          selectedStep={selectedStep}
          selectStep={selectStep}
          currentUser={currentUser}
        />
      </Col>
    </Row>
  );
};

export default CheckoutNew;
