import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { collection, getFirestore, Timestamp } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth as firebaseUIauth } from "firebaseui";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

export const googleProvider = new GoogleAuthProvider();

export const firebaseUIconfig: firebaseUIauth.Config = {
  signInFlow: "popup",
  signInOptions: [
    {
      provider: googleProvider.providerId,
      clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
    },
  ],
  credentialHelper: firebaseUIauth.CredentialHelper.GOOGLE_YOLO,
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

export const disableAutoSignIn = () => {
  firebaseUIauth.AuthUI.getInstance()?.disableAutoSignIn();
};

// COLLECTIONS
export enum CollectionName {
  PRODUCTS = "products",
  PRODUCTS_THUMBS = "products/thumbs",
  CATEGORIES = "categories",
  ORDERS = "orders",
  USERS = "users",
  STRIPE_CUSTOMERS = "stripe_customers",
}

export const productsStorageRef = ref(storage, CollectionName.PRODUCTS);
export const productsTumbnailsStorageRef = ref(
  storage,
  CollectionName.PRODUCTS_THUMBS
);
export const productsCollectionRef = collection(db, CollectionName.PRODUCTS);

export const stripeCustomersCollectionRef = collection(
  db,
  CollectionName.STRIPE_CUSTOMERS
);

// TYPES
type Common = {
  id?: string;
};

export type DbProduct = Common & {
  category: string;
  description: string;
  descriptionLong: string;
  imgUrl: string;
  thumbnailUrl: string;
  price: number;
  title: string;
  favorites: Array<string>;
};

export type DbCategory = Common & {
  name: string;
};

export type DbOrder = Common & {
  products: Array<{
    id: string;
    qty: number;
    price: number;
    totalPrice: number;
  }>;
  status: string;
  totalPrice: number;
  totalQty: number;
  deliveryInfo: {
    name: string;
    phone: string;
    address: string;
    city: string;
    postalCode: string;
    note: string;
  };
  billingInfo: {
    name: string;
    email: string;
  };
  note: string;
  clientSecret: string;
};

export type DbUser = Common & {
  email: string;
  displayName: string;
  disabled: boolean;
  createdAt: Timestamp;
  photoURL: number;
  currentOrderId: string;
  orders: any;
};

// API CALLS
export const createPaymentIntent = httpsCallable(
  functions,
  "stripe-createPaymentIntent"
);

export const updatePaymentStatus = httpsCallable(
  functions,
  "stripe-updatePaymentStatus"
);

export const getCurrentOrder = httpsCallable(
  functions,
  "orders-getCurrentOrder"
);
