import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RoleBasedRoute from "./RoleBasedRoute";
import { Home } from "../screens/Home";
import { Login } from "../screens/Login";
import { Dashboard } from "../Dashboard/Dashboard";
import AddProducts from "../Dashboard/AddProducts";
import AllProducts from "../Dashboard/AllProducts";
import Users from "../Dashboard/Users";
import ProductDetails from "../UI/ProductDetails";
import Shop from "../screens/Shop";
import Cart from "../screens/Cart";
import { selectAuthUser, selectRole } from "../../redux/slices/authSlice";
import { useAppSelector } from "../../redux/store";
import AllOrders from "../Dashboard/AllOrders";
import CheckoutNew from "../screens/checkout/CheckoutNew";
import HomeObject from "../3D/HomeObject";

const Routers = () => {
  const currentUser = useAppSelector(selectAuthUser);
  const isAdmin = useAppSelector(selectRole);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />

      <Route path="/shop" element={<Shop />} />
      <Route path="/shop/:id" element={<ProductDetails />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<CheckoutNew />} />

      <Route path="/dashboard" element={<RoleBasedRoute />}>
        <Route path="/dashboard/home" element={<Dashboard />} />
        <Route path="/dashboard/all-products" element={<AllProducts />} />
        <Route path="/dashboard/add-product" element={<AddProducts />} />
        <Route path="/dashboard/all-orders" element={<AllOrders />} />
        <Route path="/dashboard/users" element={<Users />} />
      </Route>

      <Route
        path="/login"
        element={
          currentUser ? (
            <Navigate to={isAdmin ? "/dashboard/home" : "/home"} />
          ) : (
            <Login />
          )
        }
      />

      <Route path="/model" element={<HomeObject />} />
    </Routes>
  );
};

export default Routers;
