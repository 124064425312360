import React from "react";
import "./pickupVariant.css"; // Create a CSS file for styling

export enum PickUpWay {
  Delivery = 1,
  InStore = 2,
}

export interface PickupVairiantProps {
  id: PickUpWay;
  title: string;
  description: string;
  iconName: string;
  isSelected?: boolean; // Add this prop for tracking selection
  onSelect: (id: PickUpWay) => void; // Callback to handle selection
  children: React.ReactNode;
}

const PickupVairiant: React.FC<PickupVairiantProps> = ({
  id,
  title,
  description,
  iconName,
  isSelected,
  onSelect,
}) => {
  return (
    <div
      className={`rectangle ${isSelected ? "selected" : ""}`}
      onClick={() => onSelect(id)}
    >
      <div className="icon-placeholder">
        <i className={iconName} />
      </div>
      <div className="text-container">
        <p className="rectangle-title">{title}</p>
        <p className="rectangle-description">{description}</p>
      </div>
    </div>
  );
};

export default PickupVairiant;
