import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { selectRole, selectAuthUser } from "../../redux/slices/authSlice";
import { useAppSelector } from "../../redux/store";

const RoleBasedRoute = () => {
  const currentUser = useAppSelector(selectAuthUser);
  const isAdmin = useAppSelector(selectRole);

  return currentUser && isAdmin ? <Outlet /> : <Navigate to="/login" />;
};

export default RoleBasedRoute;
