import React, { useCallback } from "react";
import ProductCard from "./ProductCard";
import { DbProduct } from "../../config/firebase";

const ProductsList = (props: { data: DbProduct[] }) => {
  const data = props.data;

  const getProductCard = useCallback((item: DbProduct) => {
    return <ProductCard item={item} key={item.id} />;
  }, []);

  return <>{data.map((item) => getProductCard(item))}</>;
};

export default ProductsList;
