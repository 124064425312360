import DateTimePicker from "../../../UI/DatePicker";
import CheckoutSegment from "../CheckoutSegment";

function PickUpTime({
  selectStep,
  selectedStep,
}: {
  selectStep(n: number): void;
  selectedStep: number;
}) {
  return (
    <CheckoutSegment
      n={2}
      done={false}
      title={"Vreme isporuke"}
      selectedId={selectedStep}
      children={<DateTimePicker onChange={(date) => selectStep(3)} />}
    />
  );
}

export default PickUpTime;
