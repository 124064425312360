import CheckoutSegment from "../CheckoutSegment";
import { DbUser } from "../../../../config/firebase";

function Payment({
  selectStep,
  selectedStep,
  currentUser,
}: {
  selectStep(n: number): void;
  selectedStep: number;
  currentUser?: DbUser;
}) {
  return (
    <CheckoutSegment
      n={4}
      done={false}
      title={"Placanje"}
      selectedId={selectedStep}
      children={<h2>Test Placanje</h2>}
    />
  );
}

export default Payment;
