import { useEffect } from "react";
import { useGetData } from "../../hooks/useGetData";
import {
  CollectionName,
  DbCategory,
  DbOrder,
  DbProduct,
  DbUser,
} from "../../config/firebase";
import { useAppDispatch } from "../../redux/store";
import { replaceAllCategories } from "../../redux/slices/categorySlice";
import { replaceAllProducts } from "../../redux/slices/productSlice";
import { useAuth } from "../../hooks/useAuth";
import {
  replaceAllOrders,
  updateCurrentOrder,
} from "../../redux/slices/orderSlice";
import { useOnDocChange } from "../../hooks/useOnDocChange";
import { setUser } from "../../redux/slices/userSlice";

function FirebaseProvider() {
  const dispatch = useAppDispatch();

  const { currentUser, isAdmin } = useAuth();

  const { data: categories } = useGetData<DbCategory>(
    CollectionName.CATEGORIES
  );
  const { data: allProducts } = useGetData<DbProduct>(CollectionName.PRODUCTS);

  const { data: allOrders } = useGetData<DbOrder>(
    CollectionName.ORDERS,
    undefined,
    currentUser && isAdmin
  );

  const { data: userDb } = useOnDocChange<DbUser>(
    CollectionName.USERS,
    currentUser?.uid
  );

  const { data: currentOrder } = useOnDocChange<DbOrder>(
    CollectionName.USERS,
    userDb && userDb.currentOrderId.length > 0
      ? `${userDb.id}/${CollectionName.ORDERS}/${userDb.currentOrderId}`
      : undefined
  );

  useEffect(() => {
    dispatch(replaceAllCategories(categories));
  }, [categories, dispatch]);

  useEffect(() => {
    dispatch(replaceAllProducts(allProducts));
  }, [allProducts, dispatch]);

  useEffect(() => {
    dispatch(replaceAllOrders(allOrders));
  }, [allOrders, dispatch]);

  useEffect(() => {
    dispatch(setUser(userDb));
  }, [userDb, dispatch]);

  useEffect(() => {
    dispatch(updateCurrentOrder(currentOrder));
  }, [currentOrder, dispatch]);

  return <></>;
}

export default FirebaseProvider;
