import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DbProduct } from "../../config/firebase";
import { ICategoryState, selectCategoryById } from "./categorySlice";
import { getSortDataBySortOption, SortOption } from "../../types/sort";
import { orderBy } from "lodash";

interface IProductState {
  items: Map<string, DbProduct>;
}

const initialState: IProductState = {
  items: new Map<string, DbProduct>(),
};

export const productSlice = createSlice({
  initialState,
  name: "productSlice",
  reducers: {
    addOrUpdateProduct: (state, action: PayloadAction<DbProduct>) => {
      const product = action.payload;
      state.items.set(product.id, product);
    },
    replaceAllProducts: (state, action: PayloadAction<Array<DbProduct>>) => {
      const products = new Map<string, DbProduct>();

      action.payload.forEach((p) => {
        products.set(p.id, p);
      });

      state.items = products;
    },
  },
});

export const { addOrUpdateProduct, replaceAllProducts } = productSlice.actions;
export default productSlice.reducer;

export const selectProductById = (
  state: { product: IProductState },
  id: string
) => {
  const items = state.product.items;

  if (items && items.size > 0) {
    return items.get(id);
  } else {
    return undefined;
  }
};

export const selectAllProducts = (state: { product: IProductState }) => {
  if (state.product.items.size > 0) {
    return Array.from(state.product.items.values());
  } else {
    return [];
  }
};

export const selectProductsByCategory = (
  state: { product: IProductState; category: ICategoryState },
  categoryId?: string,
  sortOption?: SortOption
): Array<DbProduct> => {
  let retVal: Array<DbProduct> = [];

  const categoryName = categoryId
    ? selectCategoryById(state, categoryId)?.name
    : undefined;

  if (categoryName) {
    retVal = Array.from(state.product.items.values()).filter((p) => {
      return p.category === categoryName;
    });
  } else {
    retVal = selectAllProducts(state);
  }

  if (sortOption) {
    const sortData = getSortDataBySortOption(sortOption);
    return orderBy(retVal, sortData.fieldName, sortData.type);
  } else {
    return retVal;
  }
};
