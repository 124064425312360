import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DbProduct } from "../../config/firebase";

export type CartItem = {
  id: string;
  productName: string;
  imgUrl: string;
  price: number;
  quantity: number;
  totalPrice: number;
};

interface ICartState {
  cartItems: Array<CartItem>;
  totalAmount: number;
  totalQuantity: number;
}

const initialState: ICartState = {
  cartItems: [],
  totalAmount: 0,
  totalQuantity: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<DbProduct>) => {
      const newItem = action.payload;
      const existingItem = state.cartItems.find(
        (item) => item.id === newItem.id
      );

      state.totalQuantity++;

      if (!existingItem) {
        const cartItem: CartItem = {
          id: newItem.id,
          productName: newItem.title,
          imgUrl: newItem.imgUrl,
          price: newItem.price,
          quantity: 1,
          totalPrice: newItem.price,
        };
        state.cartItems.push(cartItem);
      } else {
        existingItem.quantity++;
        existingItem.totalPrice = existingItem.totalPrice + newItem.price;
      }

      state.totalAmount = state.cartItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
    },

    deleteItem: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const existingItem = state.cartItems.find((item) => item.id === id);

      if (existingItem) {
        state.cartItems = state.cartItems.filter((item) => item.id !== id);
        state.totalQuantity = state.totalQuantity - existingItem.quantity;
      }

      state.totalAmount = state.cartItems.reduce(
        (total, item) => total + Number(item.price) * Number(item.quantity),
        0
      );
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;

export const selectTotalCartQuantity = (state: { cart: ICartState }) => {
  return state.cart.totalQuantity;
};

export const selectTotalCartAmount = (state: { cart: ICartState }) => {
  return state.cart.totalAmount;
};

export const selecCartItems = (state: { cart: ICartState }) => {
  return state.cart.cartItems;
};
