import "../../styles/home.css";
import Helmet from "../Helmet/Helmet";
import { Col, Container, Row } from "reactstrap";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ProductsList from "../UI/ProductsList";
import { SupportServices } from "../support-services/SupportServices";
import counterImg from "../../assets/images/counter-timer-img.png";
import Clock from "../UI/Clock";
import { selectAllProducts } from "../../redux/slices/productSlice";
import { useAppSelector } from "../../redux/store";
import HomeObject from "../3D/HomeObject";

export const Home = () => {
  const products = useAppSelector(selectAllProducts);
  const loadingProducts = products.length === 0;

  const year = new Date().getFullYear();

  return (
    <Helmet title={"Home"}>
      <section className="hero__section">
        <Container>
          <Row>
            <Col lg="6" md="6">
              <div className="hero__content">
                <p className="hero__subtitle">Trending product in {year}</p>
                <h2>Make Your Interior More Minimalistic & Modern </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Quaerat nulla repellat quo eaque alias corporis sunt, facilis
                  nesciunt rem fugit!
                </p>

                <motion.button whileTap={{ scale: 1.2 }} className="buy__btn">
                  <Link to="/shop">SHOP NOW</Link>
                </motion.button>
              </div>
            </Col>

            <Col lg="6" md="6">
              {/* <HomeObject /> */}
            </Col>
          </Row>
        </Container>
      </section>

      <SupportServices />

      <section className="trending__products">
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2 className="section__title">Trending Products</h2>
            </Col>

            {loadingProducts ? (
              <h5 className="fw-bold">Loading....</h5>
            ) : (
              <ProductsList data={products} />
            )}
          </Row>
        </Container>
      </section>

      <section className="timer__count">
        <Container>
          <Row>
            <Col lg="6" md="12" className="count__down-col">
              <div className="clock__top-content">
                <h4 className="text-white fs-6 mb-2">Limited Offers</h4>
                <h3 className="text-white fs-5 mb-3">Quality Armchair</h3>
              </div>
              <Clock />

              <motion.button
                whileTap={{ scale: 1.2 }}
                className="buy__btn store__btn "
              >
                <Link className="store__btn" to="/shop">
                  Visit Store
                </Link>
              </motion.button>

              <motion.button
                whileTap={{ scale: 1.2 }}
                className="buy__btn store__btn "
              >
                <Link className="store__btn" to="/model">
                  3D Model
                </Link>
              </motion.button>
            </Col>

            <Col lg="6" md="12" className="text-end counter__img">
              <img src={counterImg} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};
