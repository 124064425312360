import React, { useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { doc, deleteDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { CollectionName, DbProduct, db, storage } from "../../config/firebase";
import Helmet from "../Helmet/Helmet";
import { motion } from "framer-motion";
import { deleteObject, ref } from "firebase/storage";
import { useAppSelector } from "../../redux/store";
import { selectAllProducts } from "../../redux/slices/productSlice";

const AllProducts = () => {
  const productsData = useAppSelector(selectAllProducts);
  const loading = productsData.length === 0;

  const deleteProduct = useCallback(async (item: DbProduct) => {
    const imageRef = ref(storage, item.imgUrl);
    deleteObject(imageRef)
      .then(() => {
        toast.success("Image deleted!");
        deleteDoc(doc(db, CollectionName.PRODUCTS, item.id)).then(() => {
          toast.success("Deleted product!");
        });
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);

  const productRow = useCallback(
    (item: DbProduct) => {
      return (
        <motion.tr key={item.id} style={{ padding: "33px 0px" }}>
          <Row
            sm="5"
            xs="4"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <td>
              <motion.div whileHover={{ scale: 1.67 }}>
                <img
                  src={item.imgUrl}
                  alt=""
                  height={"auto"}
                  style={{
                    maxWidth: 150,
                  }}
                />
              </motion.div>
            </td>
            <td>{item.title}</td>
            <td>{item.category}</td>
            <td>${item.price}</td>
            <td>
              <button
                onClick={() => {
                  deleteProduct(item);
                }}
                className="btn btn-danger"
              >
                Delete
              </button>
            </td>
          </Row>
        </motion.tr>
      );
    },
    [deleteProduct]
  );

  return (
    <section>
      <Helmet title="Dashboard - All Products">
        <Container>
          {loading ? (
            <div
              className="spinner-grow"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            ></div>
          ) : (
            <Row>
              <Col lg="12">
                <table className="table">
                  <thead>
                    <tr>
                      <Row sm="5" xs="4">
                        <th>Image</th>
                        <th>Title</th>
                        <th>Category</th>
                        <th>Price</th>
                        <th></th>
                      </Row>
                    </tr>
                  </thead>
                  <tbody>{productsData.map((item) => productRow(item))}</tbody>
                </table>
              </Col>
            </Row>
          )}
        </Container>
      </Helmet>
    </section>
  );
};

export default AllProducts;
