import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/shop.css";
import Helmet from "../Helmet/Helmet";
import CommonSection from "../UI/CommonSection";
import ProductsList from "../UI/ProductsList";
import { DbCategory } from "../../config/firebase";
import { useAppSelector } from "../../redux/store";
import { selectAllCategories } from "../../redux/slices/categorySlice";
import { selectProductsByCategory } from "../../redux/slices/productSlice";
import { SortOption } from "../../types/sort";

const Shop = () => {
  const categories = useAppSelector((state) => {
    const defaultCategory: DbCategory = {
      id: undefined,
      name: "All Products",
    };
    return [defaultCategory, ...selectAllCategories(state)];
  });

  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | undefined
  >(undefined);

  const onCategoryChange = (id: string | undefined) => {
    setSelectedCategoryId(id);
  };

  const sorts = Object.values(SortOption);

  const [sortBy, setSortBy] = useState<SortOption>(sorts[0]);
  const onSortChange = (i: number) => {
    const newSort = sorts[i];
    if (newSort) {
      setSortBy(newSort);
    }
  };

  const products = useAppSelector((state) => {
    return selectProductsByCategory(state, selectedCategoryId, sortBy);
  });

  return (
    <Helmet title="Shop">
      <CommonSection title="Products" />
      <section>
        <Container>
          <Row>
            <Col lg="3" md="6">
              <div className="filter__widget">
                <select onChange={(e) => onCategoryChange(e.target.value)}>
                  {categories.map((c, index) => (
                    <option key={index} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col lg="3" md="6" className="text-end">
              <div className="filter__widget">
                <select onChange={(e) => onSortChange(+e.target.value)}>
                  {Object.values(SortOption).map((s, index) => {
                    return (
                      <option key={index} value={index}>
                        {s}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="search__box">
                <input type="text" placeholder="Search......" />
                <span>
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-0">
        <Container>
          <Row>
            {products.length === 0 ? (
              <h1 className="text-center fs-4">No products are found!</h1>
            ) : (
              <ProductsList data={products} />
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Shop;
