import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DbCategory } from "../../config/firebase";

export interface ICategoryState {
  items: Map<string, DbCategory>;
}

const initialState: ICategoryState = {
  items: new Map<string, DbCategory>(),
};

export const categorySlice = createSlice({
  initialState,
  name: "categorySlice",
  reducers: {
    addOrUpdate: (state, action: PayloadAction<DbCategory>) => {
      const category = action.payload;
      state.items.set(category.id, category);
    },
    replaceAllCategories: (state, action: PayloadAction<Array<DbCategory>>) => {
      const categories = new Map<string, DbCategory>();

      action.payload.forEach((c) => {
        categories.set(c.id, c);
      });

      state.items = categories;
    },
  },
});

export const { addOrUpdate, replaceAllCategories } = categorySlice.actions;
export default categorySlice.reducer;

export const selectCategoryById = (
  state: { category: ICategoryState },
  id: string
) => {
  const items = state.category.items;

  if (items && items.size > 0) {
    return items.get(id);
  } else {
    return undefined;
  }
};

export const selectCategoryByName = (
  state: { category: ICategoryState },
  name: string
) => {
  return selectAllCategories(state).find((c) => c.name === name);
};

export const selectAllCategories = (state: { category: ICategoryState }) => {
  if (state.category.items.size > 0) {
    return Array.from(state.category.items.values());
  } else {
    return [];
  }
};
