import { ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import {
  DbProduct,
  productsCollectionRef,
  productsStorageRef,
} from "../../config/firebase";
import { toast } from "react-toastify";
import { addDoc } from "firebase/firestore";
import { useAppSelector } from "../../redux/store";
import { selectAllCategories } from "../../redux/slices/categorySlice";

const AddProducts = () => {
  const [enterTitle, setEnterTitle] = useState("");
  const [enterShortDesc, setEnterShortDesc] = useState("");
  const [enterDescription, setEnterDescription] = useState("");
  const [enterCategory, setEnterCategory] = useState("");
  const [enterPrice, setEnterPrice] = useState(0);
  const [enterProductImg, setEnterProductImg] = useState(null);
  const [loading, setLoading] = useState(false);

  const categories = useAppSelector(selectAllCategories);

  useEffect(() => {
    if (enterCategory === "" && categories.length > 0) {
      setEnterCategory(categories[0].name);
    }
  }, [categories, enterCategory]);

  const addProduct = async (e: any) => {
    if (!enterProductImg || !enterCategory) {
      return;
    }
    setLoading(true);
    e.preventDefault();
    const imageRef = ref(productsStorageRef, enterProductImg?.name);

    const dbProduct: DbProduct = {
      title: enterTitle,
      description: enterShortDesc,
      descriptionLong: enterDescription,
      category: enterCategory,
      price: enterPrice,
      imgUrl: "",
      thumbnailUrl: "",
      favorites: [],
    };
    addDoc(productsCollectionRef, dbProduct)
      .then((uploadedProduct) => {
        console.log("Product added!", uploadedProduct);

        uploadBytes(imageRef, enterProductImg, {
          customMetadata: { productId: uploadedProduct.id },
        })
          .then((uploadedImage) => {
            console.log(`Uploaded image ${uploadedImage.ref.name}`);
            toast.success(`Product ${enterTitle} is added!`);

            setLoading(false);
          })
          .catch((e) => {
            toast.error(`Failed to upload image ${enterProductImg.name}`);
            console.log(e);
            setLoading(false);
          });
      })
      .catch((e) => {
        toast.error(`Failed to add product ${enterTitle}`);
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <section>
      <Container>
        <Row>
          <Col lg="12">
            <>
              <h4 className="mb-5">Add Product</h4>
              <Form onSubmit={addProduct}>
                <FormGroup disabled={loading} className="form__group">
                  <span>Product title</span>
                  <input
                    type="text"
                    placeholder="Double sofa"
                    value={enterTitle}
                    onChange={(e) => setEnterTitle(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup className="form__group">
                  <span>Short Description</span>
                  <input
                    type="text"
                    placeholder="lorem......"
                    value={enterShortDesc}
                    onChange={(e) => setEnterShortDesc(e.target.value)}
                    required
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <span>Description</span>
                  <input
                    type="text"
                    placeholder="Description....."
                    value={enterDescription}
                    onChange={(e) => setEnterDescription(e.target.value)}
                    required
                  />
                </FormGroup>

                <div className="d-flex align-items-center justify-content-between gap-5">
                  <FormGroup className="form__group w-50">
                    <span>Price $</span>
                    <input
                      type="number"
                      placeholder=""
                      value={enterPrice}
                      onChange={(e) => setEnterPrice(+e.target.value)}
                      required
                    />
                  </FormGroup>

                  <FormGroup className="form__group w-50">
                    <span>Category</span>
                    <select
                      className="w-70 p-2"
                      onChange={(e) => setEnterCategory(e.target.value)}
                      required
                    >
                      <>
                        {categories.map((c, index) => (
                          <option key={index} value={c.name}>
                            {c.name}
                          </option>
                        ))}
                      </>
                    </select>
                  </FormGroup>
                </div>

                <div>
                  <FormGroup className="form__group ">
                    <span>Product Image</span>
                    <input
                      type="file"
                      onChange={(e) => setEnterProductImg(e.target.files[0])}
                      required
                    />
                  </FormGroup>
                </div>

                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading && (
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {loading ? <>Adding...</> : <>Add Product</>}
                </button>
              </Form>
            </>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AddProducts;
