import React from "react";
import { auth, googleProvider } from "../../config/firebase";
import { signInWithPopup } from "firebase/auth";
import { toast } from "react-toastify";
import Helmet from "../Helmet/Helmet";

export const Login = () => {
  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      toast.success(
        `Welcome: ${JSON.stringify(auth?.currentUser?.displayName)}`
      );
    } catch (err) {
      console.error(err);
      toast.success(`${err}`);
    }
  };

  return (
    <Helmet title={"Login"}>
      <button onClick={signInWithGoogle}> Sign In With Google</button>
    </Helmet>
  );
};
