import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "remixicon/fonts/remixicon.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/index.css";
import FirebaseProvider from "./components/FirebaseProvider/FirebaseProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

let persistor = persistStore(store);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <FirebaseProvider />
        <ToastContainer
          autoClose={1876}
          pauseOnHover={false}
          closeOnClick={true}
        />
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
