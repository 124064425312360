import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Routers from "../routers/Routers";
import { useLocation } from "react-router-dom";
import AdminNav from "../Dashboard/AdminNav";
import "./layout.css";

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {pathname.startsWith("/dashboard") ? <AdminNav /> : <Header />}
      <div>
        <Routers />
      </div>
      {!pathname.startsWith("/checkout") && <Footer />}
    </>
  );
};

export default Layout;
