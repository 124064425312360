import { useEffect } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "../config/firebase";
import {
  logout,
  selectRole,
  selectAuthUser,
  setRole,
  setAuthUser,
} from "../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";

export const useAuth = () => {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(selectAuthUser);
  const isAdmin = useAppSelector(selectRole);

  useEffect(() => {
    const getClaims = async (user: User) => {
      const response = await user.getIdTokenResult(false);
      dispatch(setRole(response?.claims?.admin === true));
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(setAuthUser(user));
        getClaims(user);
      } else {
        dispatch(logout());
      }
    });
  });

  return {
    currentUser,
    isAdmin,
  };
};
