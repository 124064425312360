import CheckoutSegment from "../CheckoutSegment";
import YourData from "./YourData";
import { DbUser } from "../../../../config/firebase";

function YourDataHeader({
  selectStep,
  selectedStep,
  currentUser,
}: {
  selectStep(n: number): void;
  selectedStep: number;
  currentUser?: DbUser;
}) {
  return (
    <CheckoutSegment
      n={3}
      title={"Vasi podaci"}
      selectedId={selectedStep}
      children={<YourData user={currentUser} />}
      done={false}
    />
  );
}

export default YourDataHeader;
