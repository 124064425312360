/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 scene.gltf 
Author: Lassi Kaukonen (https://sketchfab.com/thesidekick)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/jade-plant-6db9e1a94ee94301a02fd851ef4b4a67
Title: Jade plant
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Plant(props) {
  const { nodes, materials } = useGLTF("./jade_plant/scene.gltf");
  return (
    <group {...props} position={[0, -1.3, 0]} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Object_2.geometry}
          material={materials.Jade_plant}
        />
        <mesh
          geometry={nodes.Object_3.geometry}
          material={materials.Jade_plant}
        />
        <mesh
          geometry={nodes.Object_4.geometry}
          material={materials.Jade_plant}
        />
        <mesh
          geometry={nodes.Object_5.geometry}
          material={materials.Jade_plant}
        />
        <mesh
          geometry={nodes.Object_6.geometry}
          material={materials.Jade_plant}
        />
        <mesh
          geometry={nodes.Object_7.geometry}
          material={materials.Jade_plant}
        />
        <mesh
          geometry={nodes.Object_8.geometry}
          material={materials.Jade_plant}
        />
        <mesh
          geometry={nodes.Object_9.geometry}
          material={materials.Jade_plant}
        />
        <mesh
          geometry={nodes.Object_10.geometry}
          material={materials["Material.001"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/scene.gltf");
