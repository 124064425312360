import React, { useCallback, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "../../styles/product-card.css";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import { cartActions } from "../../redux/slices/cartSlice";
import { DbProduct } from "../../config/firebase";

const ProductCard = ({ item }: { item: DbProduct }) => {
  const dispatch = useDispatch();
  const blurDivsRef = useRef<HTMLDivElement>(null);

  const addToCart = useCallback(() => {
    dispatch(cartActions.addItem(item));

    toast.success("Product added successfully");
  }, [dispatch, item]);

  const getProductCard = useCallback(
    (item: DbProduct) => {
      return (
        <Col lg="3" md="4" className="mb-2">
          <div className="product__item" ref={blurDivsRef}>
            <motion.div className="product__img" whileHover={{ scale: 1.1 }}>
              <Link to={`/shop/${item.id}`}>
                <div
                  className="blur-load"
                  style={{ backgroundImage: `url(${item.thumbnailUrl})` }}
                >
                  <img
                    className="product__img"
                    src={item.imgUrl}
                    alt=""
                    loading="lazy"
                  />
                </div>
              </Link>
            </motion.div>
            <div className="p-2 product__info">
              <h3 className="product__name">
                <Link to={`/shop/${item.id}`}>{item.title}</Link>
              </h3>
              <span>{item.category}</span>
            </div>
            <div className="product__card-bottom d-flex align-items-center justify-content-between p-2">
              <span className="price">${item.price}</span>
              <motion.span whileTap={{ scale: 1.2 }} onClick={addToCart}>
                <i className="ri-add-line"></i>
              </motion.span>
            </div>
          </div>
        </Col>
      );
    },
    [addToCart]
  );

  useEffect(() => {
    const blurDivs = blurDivsRef.current?.querySelectorAll(".blur-load");

    blurDivs?.forEach((div) => {
      const img = div.querySelector("img");

      function loaded() {
        div.classList.add("loaded");
      }

      if (img.complete) {
        loaded();
      } else {
        img.addEventListener("load", loaded);
      }

      return () => {
        img.removeEventListener("load", loaded);
      };
    });
  }, []);

  return getProductCard(item);
};

export default ProductCard;
