import React from "react";
import { useAppSelector } from "../../redux/store";
import { selectAuthUser } from "../../redux/slices/authSlice";
import { StyledFirebaseAuth } from "react-firebaseui";
import { auth, firebaseUIconfig } from "../../config/firebase";

const SignUpWithGoogle = (props: { message?: string }) => {
  const currentUser = useAppSelector(selectAuthUser);
  const message = props.message;

  return (
    <>
      {!currentUser && (
        <div className="mb-3">
          {message && (
            <div className="d-flex justify-content-center">
              <h3>{message}</h3>
            </div>
          )}
          <StyledFirebaseAuth uiConfig={firebaseUIconfig} firebaseAuth={auth} />
        </div>
      )}
    </>
  );
};

export default SignUpWithGoogle;
