import React from "react";
import { FormGroup, Form, Label, Input, Button } from "reactstrap";
import { DeliveryInfoType } from "../../../../types/checkout";
import "../../../../styles/delivery-form.css";

interface DeliveryFormProps {
  done: boolean;
  submitedData: DeliveryInfoType | undefined;
  onEditClicked: () => void;
  onSubmit(data: DeliveryInfoType): void;
}

const DeliveryForm = ({
  done,
  submitedData = undefined,
  onEditClicked,
  onSubmit,
}: DeliveryFormProps) => {
  const validate = (e: any) => {
    e.preventDefault();

    const data: DeliveryInfoType = {
      firstName: e.target.first_name.value,
      lastName: e.target.last_name.value,
      mobile: e.target.mobile.value,
      address: e.target.address.value,
      city: e.target.city.value,
      postalCode: e.target.postal.value,
      note: e.target.note.value,
    };

    // todo: validate

    onSubmit(data);
  };

  const formGroupClassName = `form__group ${done ? "read-only-input" : ""}`;

  return (
    <div>
      <Form className="delivery__form" onSubmit={validate} hidden={done}>
        <FormGroup className={formGroupClassName}>
          <Label for="input_first_name">{"First Name *"}</Label>
          <Input
            id={"input_first_name"}
            type="text"
            name="first_name"
            required
            readOnly={done}
          />
        </FormGroup>

        <FormGroup className={formGroupClassName}>
          <Label for="input_last_name">{"Last Name *"}</Label>
          <Input
            id={"input_last_name"}
            type="text"
            name="last_name"
            required
            readOnly={done}
          />
        </FormGroup>

        <FormGroup className={formGroupClassName}>
          <Label for="input_mobile">{"Mobile *"}</Label>
          <Input
            id={"input_mobile"}
            type="text"
            name="mobile"
            required
            readOnly={done}
          />
        </FormGroup>

        <FormGroup className={formGroupClassName}>
          <Label for="input_address">{"Street Address *"}</Label>
          <Input
            id={"input_address"}
            type="text"
            required
            name="address"
            readOnly={done}
          />
        </FormGroup>

        <FormGroup className={formGroupClassName}>
          <Label for="input_city">{"City *"}</Label>
          <Input
            id={"input_city"}
            type="text"
            name="city"
            required
            readOnly={done}
          />
        </FormGroup>

        <FormGroup className={formGroupClassName}>
          <Label for="input_postal">{"Postal Code *"}</Label>
          <Input id={"input_postal"} type="text" required name="postal" />
        </FormGroup>

        <FormGroup className={formGroupClassName}>
          <Label for="input_note">{"Note"}</Label>
          <Input
            type="textarea"
            id="input_note"
            required={false}
            name="note"
            readOnly={done}
          />
        </FormGroup>

        <Button type="submit">Next</Button>
      </Form>
      <div hidden={!done || !submitedData}>
        {submitedData && (
          <>
            <p className="preview-data">
              {submitedData.firstName} {submitedData.lastName}
            </p>
            <p className="preview-data">{submitedData.mobile}</p>
            <p className="preview-data">{submitedData.address}</p>
            <p className="preview-data">
              {submitedData.city}, {submitedData.postalCode}
            </p>
            <p className="preview-data">{submitedData.note}</p>
            <Button className="btn-link" onClick={onEditClicked}>
              Edit
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default DeliveryForm;
