import { Canvas } from "@react-three/fiber";
import { ARButton, XR } from "@react-three/xr";
import { ReactNode } from "react";

function XRContainer({ children }: { children: ReactNode }) {
  return (
    <>
      <ARButton />
      <Canvas>
        <XR>
          <>{children}</>
        </XR>
      </Canvas>
    </>
  );
}

export default XRContainer;
