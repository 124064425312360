import { OrbitControls } from "@react-three/drei";
import XRContainer from "./XRContainer";
import { Plant } from "./Plant";

const HomeObject = () => {
  return (
    <div style={{ height: "100vh" }}>
      <XRContainer>
        <OrbitControls autoRotate={true} autoRotateSpeed={2} />
        <spotLight position={[10, 15, 10]} intensity={2} />
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 5]} intensity={2} />
        <directionalLight position={[-10, -10, -5]} intensity={1} />

        <Plant />
      </XRContainer>
    </div>
  );
};

export default HomeObject;
