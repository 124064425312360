import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../config/firebase";

export const useOnDocChange = <T,>(
  pathToCollection: string,
  docId?: string
) => {
  const [data, setData] = useState<T | undefined>(undefined);

  useEffect(() => {
    if (docId && docId.length > 0) {
      const getData = async () => {
        const documentRef = doc(db, pathToCollection, docId);

        onSnapshot(documentRef, (snapshot) => {
          setData({ ...snapshot.data(), id: snapshot.id } as T);
          console.log({ ...snapshot.data(), id: snapshot.id });
        });
      };

      try {
        getData();
      } catch (e) {
        console.error(e);
      }
    } else {
      setData(undefined);
    }
  }, [docId, pathToCollection]);

  return { data };
};
