import React, { useState } from "react";
import { DbUser } from "../../../../config/firebase";
import SignUpWithGoogle from "../../../UI/SignUpWithGoogle";
import YourDataForm from "./YourDataForm";
import { YourDataType } from "../../../../types/checkout";

function YourData({ user }: { user?: DbUser }) {
  const [yourInfoFormData, setYourInfoFormData] = useState<
    YourDataType | undefined
  >(undefined);

  return (
    <div>
      {user ? (
        <div>
          <p className="preview-data">{user.displayName}</p>
          <p className="preview-data">{user.email}</p>
        </div>
      ) : (
        <>
          <SignUpWithGoogle />
          <p>----- OR -----</p>
          <YourDataForm
            done={false}
            onEditClicked={() => {}}
            onSubmit={() => {}}
            submitedData={yourInfoFormData}
          />
        </>
      )}
    </div>
  );
}

export default YourData;
