import React from "react";
import { FormGroup, Form, Label, Input, Button } from "reactstrap";
import { YourDataType } from "../../../../types/checkout";
import "../../../../styles/delivery-form.css";

interface YourDataFormProps {
  done: boolean;
  submitedData: YourDataType | undefined;
  onEditClicked: () => void;
  onSubmit(data: YourDataType): void;
}

const YourDataForm = ({
  done,
  submitedData = undefined,
  onEditClicked,
  onSubmit,
}: YourDataFormProps) => {
  const validate = (e: any) => {
    e.preventDefault();

    const data: YourDataType = {
      firstName: e.target.first_name.value,
      lastName: e.target.last_name.value,
      mobile: e.target.mobile.value,
      email: e.target.email.value,
    };

    // todo: validate

    onSubmit(data);
  };

  const formGroupClassName = `form__group ${done ? "read-only-input" : ""}`;

  return (
    <div>
      <Form className="delivery__form" onSubmit={validate} hidden={done}>
        <FormGroup className={formGroupClassName}>
          <Label for="input_first_name">{"First Name *"}</Label>
          <Input
            id={"input_first_name"}
            type="text"
            name="first_name"
            required
            readOnly={done}
          />
        </FormGroup>

        <FormGroup className={formGroupClassName}>
          <Label for="input_last_name">{"Last Name *"}</Label>
          <Input
            id={"input_last_name"}
            type="text"
            name="last_name"
            required
            readOnly={done}
          />
        </FormGroup>

        <FormGroup className={formGroupClassName}>
          <Label for="input_mobile">{"Mobile *"}</Label>
          <Input
            id={"input_mobile"}
            type="text"
            name="mobile"
            required
            readOnly={done}
          />
        </FormGroup>

        <FormGroup className={formGroupClassName}>
          <Label for="input_email">{"Email *"}</Label>
          <Input
            id={"input_email"}
            type="text"
            required
            name="email"
            readOnly={done}
          />
        </FormGroup>

        <Button type="submit">Next</Button>
      </Form>
      <div hidden={!done || !submitedData}>
        {submitedData && (
          <>
            <p className="preview-data">
              {submitedData.firstName} {submitedData.lastName}
            </p>
            <p className="preview-data">{submitedData.mobile}</p>
            <p className="preview-data">{submitedData.email}</p>

            <Button className="btn-link" onClick={onEditClicked}>
              Edit
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default YourDataForm;
