import { Container, Row, Col } from "reactstrap";
import "../../styles/dashboard.css";
import Helmet from "../Helmet/Helmet";
import { selectAllProducts } from "../../redux/slices/productSlice";
import { useAppSelector } from "../../redux/store";
import { selectAllOrders } from "../../redux/slices/orderSlice";

export const Dashboard = () => {
  const products = useAppSelector(selectAllProducts);
  const orders = useAppSelector(selectAllOrders);
  const users = [];

  return (
    <>
      <section>
        <Helmet title="Dashboard">
          <Container>
            <Row>
              <Col className="lg-3">
                <div className="revenue__box">
                  <h5>Total Sales</h5>
                  <span>$7890</span>
                </div>
              </Col>
              <Col className="lg-3">
                <div className="order__box">
                  <h5>Orders</h5>
                  <span>{orders.length}</span>
                </div>
              </Col>
              <Col className="lg-3">
                <div className="products__box">
                  <h5>Total Products</h5>
                  <span>{products.length}</span>
                </div>
              </Col>
              <Col className="lg-3">
                <div className="users__box">
                  <h5>Total Users</h5>
                  <span>{users.length}</span>
                </div>
              </Col>
            </Row>
          </Container>
        </Helmet>
      </section>
    </>
  );
};
