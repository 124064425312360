import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DbUser } from "../../config/firebase";

interface IUserState {
  user: DbUser | undefined;
}

const initialState: IUserState = {
  user: undefined,
};

export const userSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    clear: () => initialState,
    setUser: (state, action: PayloadAction<DbUser | undefined>) => {
      state.user = action.payload;
    },
  },
});

export const { clear, setUser } = userSlice.actions;
export default userSlice.reducer;

export const selectUser = (state: { user: IUserState }) => {
  return state.user.user;
};
