import { useState } from "react";
import CheckoutSegment from "../CheckoutSegment";
import PickupVairiant, {
  PickUpWay,
  PickupVairiantProps,
} from "./PickupVariant";
import { DateTimePickerID } from "../../../UI/DatePicker";
import DeliveryForm from "./DeliveryForm";
import { DeliveryInfoType } from "../../../../types/checkout";

function PickUpWayHeader({
  selectStep,
  selectedStep,
}: {
  selectStep(n: number): void;
  selectedStep: number;
}) {
  const [selectedRectangleId, setSelectedRectangleId] = useState<
    PickUpWay | undefined
  >(undefined);

  const handleSelectRectangle = (id: number) => {
    setSelectedRectangleId(id);
    if (id === PickUpWay.InStore) {
      selectStep(2);
      setTimeout(() => {
        document.getElementById(DateTimePickerID)?.focus();
      }, 333);
    }
  };

  const [deliveryFormDone, setDeliveryFormDone] = useState(false);
  const [deliveryFormData, setDeliveryFormData] = useState<
    DeliveryInfoType | undefined
  >(undefined);

  const deliveryFormOnSubmit = (data: DeliveryInfoType) => {
    setDeliveryFormDone(true);
    setDeliveryFormData(data);
    selectStep(2);
    window.scrollTo(0, 0);
  };

  const editDeliveryForm = () => {
    setDeliveryFormDone(false);
    selectStep(1);
  };

  const rectanglesData: PickupVairiantProps[] = [
    {
      id: PickUpWay.Delivery,
      title: "Dostava",
      description: "Na adresu u Novom Sadu",
      iconName: "ri-truck-line",
      onSelect: handleSelectRectangle,
      children: (
        <DeliveryForm
          done={deliveryFormDone}
          onSubmit={deliveryFormOnSubmit}
          onEditClicked={editDeliveryForm}
          submitedData={deliveryFormData}
        />
      ),
    },
    {
      id: PickUpWay.InStore,
      title: "Preuzimanje",
      description: "U prodavnici u Novom Sadu",
      iconName: "ri-store-3-line",
      onSelect: handleSelectRectangle,
      children: (
        <div>
          <p className="preview-data">
            {"Licno preuzimanje u Bulevar Patrijarha Pavla 57, Novi Sad"}
          </p>
        </div>
      ),
    },
  ];

  return (
    <CheckoutSegment
      n={1}
      done={false}
      title={"Nacin isporuke"}
      selectedId={selectedStep}
      children={
        <>
          <div className="rectangle-container">
            {rectanglesData.map((rectangle) => (
              <PickupVairiant
                key={rectangle.id}
                {...rectangle}
                isSelected={rectangle.id === selectedRectangleId}
              />
            ))}
          </div>
          {selectedRectangleId !== undefined && (
            <div>
              {rectanglesData.map((rectangle) => (
                <div hidden={rectangle.id !== selectedRectangleId}>
                  {rectangle.children}
                </div>
              ))}
            </div>
          )}
        </>
      }
    />
  );
}

export default PickUpWayHeader;
