import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DateTimePickerID = "delivery_date_time_picker_id";

const DateTimePicker = ({ onChange }: { onChange(date: Date): void }) => {
  const currentDateTime = new Date();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onChange(date);
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    // 0 is Sunday, 6 is Saturday
    return day !== 0;
  };

  const minDate = currentDateTime.setHours(0, 0, 0, 0);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(DateTimePickerID)?.focus();
    }, 333);
  }, []);

  return (
    <div>
      <DatePicker
        id={DateTimePickerID}
        selected={selectedDate}
        onChange={handleDateChange}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={30}
        dateFormat="dd MMM 'u' HH:mm'h'"
        weekStartsOn={1} // 1 is Monday
        filterDate={isWeekday}
        minTime={new Date(0, 0, 0, 8, 0)}
        maxTime={new Date(0, 0, 0, 21, 0)}
        minDate={minDate}
      />
    </div>
  );
};

export default DateTimePicker;
