export enum SortOption {
  NAME = "Name",
  PRICE_LOWEST_FIRST = "Price - Lowest first",
  PRICE_HIGHEST_FIRST = "Price - Highest first",
}

export const getSortDataBySortOption = (
  option: SortOption
): { fieldName: string; type: string } => {
  switch (option) {
    case SortOption.NAME:
      return {
        fieldName: "title",
        type: "asc",
      };
    case SortOption.PRICE_HIGHEST_FIRST:
      return {
        fieldName: "price",
        type: "desc",
      };
    case SortOption.PRICE_LOWEST_FIRST:
      return {
        fieldName: "price",
        type: "asc",
      };
    default:
      return {
        fieldName: "title",
        type: "asc",
      };
  }
};
