import { useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import { DbOrder } from "../../config/firebase";
import Helmet from "../Helmet/Helmet";
import { motion } from "framer-motion";
import { useAppSelector } from "../../redux/store";
import { selectAllOrders } from "../../redux/slices/orderSlice";

const AllOrders = () => {
  const ordersData = useAppSelector(selectAllOrders);
  const loading = ordersData.length === 0;

  const openOrder = useCallback((id: string) => {
    toast.success(`Order id: ${id}`);
  }, []);

  const orderRow = useCallback(
    (item: DbOrder) => {
      return (
        <motion.tr key={item.id} style={{ padding: "33px 0px" }}>
          <Row
            sm="5"
            xs="4"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <td>{item.totalPrice}</td>
            <td>{item.status}</td>
            <td>{item.products.length}</td>
            <td>
              <button
                onClick={() => {
                  openOrder(item.id);
                }}
                className="btn btn-danger"
              >
                Open
              </button>
            </td>
          </Row>
        </motion.tr>
      );
    },
    [openOrder]
  );

  return (
    <section>
      <Helmet title="Dashboard - AllOrders">
        <Container>
          {loading ? (
            <div
              className="spinner-grow"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            ></div>
          ) : (
            <Row>
              <Col lg="12">
                <table className="table">
                  <thead>
                    <tr>
                      <Row sm="5" xs="4">
                        <th>User Id</th>
                        <th>Status</th>
                        <th>Products</th>
                        <th></th>
                      </Row>
                    </tr>
                  </thead>
                  <tbody>{ordersData.map((item) => orderRow(item))}</tbody>
                </table>
              </Col>
            </Row>
          )}
        </Container>
      </Helmet>
    </section>
  );
};

export default AllOrders;
