import React, { useState, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import "../../styles/product-details.css";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import ProductsList from "./ProductsList";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { cartActions } from "../../redux/slices/cartSlice";
import Helmet from "../Helmet/Helmet";
import CommonSection from "./CommonSection";
import {
  selectProductById,
  selectProductsByCategory,
} from "../../redux/slices/productSlice";
import { selectCategoryByName } from "../../redux/slices/categorySlice";

const ProductDetails = () => {
  const { id } = useParams();
  const product = useAppSelector((state) => selectProductById(state, id));
  const [tab, setTab] = useState("desc");
  const reviewUser = useRef("");
  const reviewMsg = useRef("");
  const dispatch = useAppDispatch();

  const [rating, setRating] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();

    const reviewUserName = reviewUser.current;
    const reviewUserMsg = reviewMsg.current;

    const reviewObj = {
      userName: reviewUserName,
      text: reviewUserMsg,
      rating,
    };

    console.log(reviewObj);
    toast.success("Review submitted");
  };

  const addToCart = () => {
    dispatch(cartActions.addItem(product));

    toast.success("Product added successfully");
  };

  const relatedProducts = useAppSelector((state) => {
    if (product) {
      return selectProductsByCategory(
        state,
        selectCategoryByName(state, product.category).id
      );
    } else {
      return [];
    }
  }).slice(0, 5);

  return (
    <Helmet title={product?.title ?? "Not found"}>
      {product ? (
        <>
          <CommonSection title={product.title} />

          <section className="">
            <Container>
              <Row>
                <Col lg="6">
                  <img src={product.imgUrl} alt="" />
                </Col>

                <Col lg="6">
                  <div className="product__details">
                    <h2>{product.title}</h2>
                    <div className="product__rating d-flex align-items-center gap-5 mb-3">
                      <div>
                        <span>
                          <i className="ri-star-s-fill"></i>
                        </span>
                        <span>
                          <i className="ri-star-s-fill"></i>
                        </span>
                        <span>
                          <i className="ri-star-s-fill"></i>
                        </span>
                        <span>
                          <i className="ri-star-s-fill"></i>
                        </span>
                        <span>
                          <i className="ri-star-half-s-line"></i>
                        </span>
                      </div>

                      <p>{/* (<span>{avgRating}</span> ratings) */}</p>
                    </div>

                    <div className="d-flex align-items-center gap-5">
                      <span className="product__price">${product.price}</span>
                      <span>Category: {product.category}</span>
                    </div>
                    <p className="mt-3">{product.description}</p>

                    <motion.button
                      whileTap={{ scale: 1.2 }}
                      className="buy__btn"
                      onClick={addToCart}
                    >
                      Add to Cart
                    </motion.button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section>
            <Container>
              <Row>
                <Col lg="12">
                  <div className="tab__wrapper d-flex align-items-center gap-5">
                    <h6
                      className={`${tab === "desc" ? "active__tab" : ""}`}
                      onClick={() => setTab("desc")}
                    >
                      Description
                    </h6>
                    <h6
                      className={`${tab === "rev" ? "active__tab" : ""}`}
                      onClick={() => setTab("rev")}
                    >
                      Reviews
                    </h6>
                  </div>

                  {tab === "desc" ? (
                    <div className="tab__content mt-5">
                      <p>{product.description}</p>
                    </div>
                  ) : (
                    <div className="product__review mt-5">
                      <div className="review__wrapper">
                        {/* <ul>
                      {reviews?.map((item, index) => (
                        <li kew={index} className="mb-4">
                          <h6>Jhon Doe</h6>
                          <span>{item.rating} ( rating)</span>
                          <p>{item.text}</p>
                        </li>
                      ))}
                    </ul> */}

                        <div className="review__form">
                          <h4>Leave your experience</h4>
                          <form action="" onSubmit={submitHandler}>
                            <div className="form__group">
                              <input
                                type="text"
                                placeholder="Enter name"
                                // ref={reviewUser}
                                required
                              />
                            </div>

                            <div className="form__group d-flex align-items-center gap-5 rating__group">
                              <motion.span
                                whileTap={{ scale: 1.2 }}
                                onClick={() => setRating(1)}
                              >
                                1<i className="ri-star-s-fill"></i>
                              </motion.span>
                              <motion.span
                                whileTap={{ scale: 1.2 }}
                                onClick={() => setRating(2)}
                              >
                                2<i className="ri-star-s-fill"></i>
                              </motion.span>
                              <motion.span
                                whileTap={{ scale: 1.2 }}
                                onClick={() => setRating(3)}
                              >
                                3<i className="ri-star-s-fill"></i>
                              </motion.span>
                              <motion.span
                                whileTap={{ scale: 1.2 }}
                                onClick={() => setRating(4)}
                              >
                                4<i className="ri-star-s-fill"></i>
                              </motion.span>
                              <motion.span
                                whileTap={{ scale: 1.2 }}
                                onClick={() => setRating(5)}
                              >
                                5<i className="ri-star-s-fill"></i>
                              </motion.span>
                            </div>

                            <div className="form__group">
                              <textarea
                                // ref={reviewMsg}
                                rows={4}
                                placeholder="Review Message..."
                                required
                              />
                            </div>

                            <motion.button
                              whileTap={{ scale: 1.2 }}
                              type="submit"
                              className="buy__btn"
                            >
                              Submit
                            </motion.button>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col lg="12" className="mt-5">
                  <h2 className="related__title">You might also like</h2>
                </Col>

                <ProductsList data={relatedProducts} />
              </Row>
            </Container>
          </section>
        </>
      ) : (
        <h2>Product not found!</h2>
      )}
    </Helmet>
  );
};

export default ProductDetails;
