import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";

interface IUserState {
  user: User | undefined;
  isAdmin: boolean;
}

const initialState: IUserState = {
  user: undefined,
  isAdmin: false,
};

export const authSlice = createSlice({
  initialState,
  name: "authSlice",
  reducers: {
    logout: () => initialState,
    setAuthUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
    setRole: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
  },
});

export const { logout, setAuthUser, setRole } = authSlice.actions;
export default authSlice.reducer;

export const selectAuthUser = (state: { auth: IUserState }) => {
  return state.auth.user;
};

export const selectRole = (state: { auth: IUserState }) => {
  return state.auth.isAdmin;
};
