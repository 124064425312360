import React from "react";
import "../../../styles/checkout-segment.css";
import { Col, Container, Row } from "reactstrap";

interface SegmentProps {
  n: number;
  title: string;
  selectedId: number;
  onHeaderClick?: (n: number) => void;
  children?: React.ReactNode;
  done: boolean;
}

export const CheckoutSegment = ({
  n,
  title,
  selectedId,
  onHeaderClick,
  children,
}: SegmentProps) => {
  const selected = selectedId === n;
  const isDone = n < selectedId;

  return (
    <Container>
      <Row
        onClick={() => onHeaderClick?.(n)}
        className={`${selected ? "" : "pointer"}`}
      >
        <Col sm={1}>
          <div
            className={`circle ${selected ? "selected" : ""} ${
              isDone ? "done" : ""
            }`}
          >
            {n}
          </div>
        </Col>
        <Col sm={10} className="align-self-center py-3 py-sm-0">
          <h3>{title}</h3>
        </Col>
      </Row>

      {selected || isDone ? (
        <Row hidden={!(selected || isDone)}>
          <Col sm={11} className="offset-sm-1">
            <div>{children}</div>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Row className={`py-sm-4 py-1 ${selected ? "" : "pointer"}`}>
        <hr />
      </Row>
    </Container>
  );
};

export default CheckoutSegment;
